<template>
  <div class="wrapper">

    <div>
      <h3>Privacy Policy</h3>
      <p>Last Updated: Sep 15 2022</p>

      <p>
        Thank you for visiting the Privacy Policy of Fast Loading Dispatch Inc.
        This Privacy Policy explains how Fast Loading Dispatch Inc
        (collectively, “Fast Loading Dispatch Inc”, “we”, “us”, or “our”)
        collects, uses, and shares information about you (“you”, “yours” or
        “user”) when you access or use our websites (“Services”).
      </p>
      <p>
        Users are responsible for any third-party data they provide or share
        through the Services and confirm that they have the third-party's
        consent to provide such data to us.
      </p>
      <br />

      <h3>Information We Collect</h3>

      <p>
        <strong>Log and Usage Information: </strong>such as browser type you
        use, hardware model, operating system, IP address, unique device
        identifiers, access times, pages viewed, links clicked, and browsing
        behavior such as time spent, what site you came from, what site you
        visit when you leave us, and browsing behavior.
      </p>
      <p>
        <strong>Account Information: </strong>if you create an account on the
        Services, we collect the information you provide to us related to the
        account, such as first and last name, username, password, and email
        address.
      </p>

      <br />

      <h3>How We Use Your Information</h3>
      <p>
        We use information we collect about you to provide, maintain, and
        improve our Services and other interactions we have with you. For
        example, we use the information collected to:
      </p>
      <ul>
        <li>
          <p>Facilitate and improve our online experience;</p>
        </li>
        <li>
          <p>
            Provide and deliver products and services, perform authentication,
            process transactions and returns, and send you related information,
            including confirmations, receipts, invoices, customer experience
            surveys, and product or Services-related notices;
          </p>
        </li>
        <li>
          <p>Process and deliver promotions;</p>
        </li>
        <li>
          <p>
            Respond to your comments and questions and provide customer service;
          </p>
        </li>
        <li>
          <p>
            If you have indicated to us that you wish to receive notifications
            or promotional messages;
          </p>
        </li>
        <li>
          <p>
            Detect, investigate and prevent fraudulent transactions and other
            illegal activities and protect our rights and property and others;
          </p>
        </li>
        <li>
          <p>Comply with our legal and financial obligations;</p>
        </li>
        <li>
          <p>Monitor and analyze trends, usage, and activities;</p>
        </li>
        <li>
          <p>
            Provide and allow our partners to provide advertising and marketing
            targeted toward your interests.
          </p>
        </li>
      </ul>
      <br />
      <h3>How We May Share Information</h3>
      <p>We may share your Personal Information in the following situations:</p>
      <ul>
        <li>
          <p>
            <strong>Third Party Services Providers. </strong>We may share data
            with service providers, vendors, contractors, or agents who complete
            transactions or perform services on our behalf, such as those that
            assist us with our business and internal operations like shipping
            and delivery, payment processing, fraud prevention, customer
            service, gift cards, experiences, personalization, marketing, and
            advertising;
          </p>
        </li>
        <li>
          <p>
            <strong>Change in Business. </strong>We may share data in connection
            with a corporate business transaction, such as a merger or
            acquisition of all or a portion of our business to another company,
            joint venture, corporate reorganization, insolvency or bankruptcy,
            financing or sale of company assets;
          </p>
        </li>
        <li>
          <p>
            <strong>To Comply with Law. </strong>We may share data to facilitate
            legal process from lawful requests by public authorities, including
            to meet national security or law enforcement demands as permitted by
            law.
          </p>
        </li>
        <li>
          <p>
            <strong>With Your Consent. </strong>We may share data with third
            parties when we have your consent.
          </p>
        </li>
        <li>
          <p>
            <strong>With Advertising and Analytics Partners. </strong>See the
            section entitled “Advertising and Analytics” below.
          </p>
        </li>
      </ul>
      <br />

      <h3>Data Security</h3>
      <p>
        We implement commercially reasonable security measures designed to
        protect your information. Despite our best efforts, however, no security
        measures are completely impenetrable.
      </p>
      <br />
      <h3>Data Retention</h3>
      <p>
        We store the information we collect about you for as long as necessary
        for the purpose(s) for which we collected it or for other legitimate
        business purposes, including to meet our legal, regulatory, or other
        compliance obligations.
      </p>
      <br />

      <h3>Age Limitations</h3>
      <p>
        Our Service is intended for adults ages 18 years and above. We do not
        knowingly collect personally identifiable information from children. If
        you are a parent or legal guardian and think your child under 13 has
        given us information, please email or write to us at the address listed
        at the end of this Privacy Policy. Please mark your inquiries “COPPA
        Information Request.”
      </p>
      <br />
      <h3>Changes to this Privacy Policy</h3>
      <p>
        Fast Loading Dispatch Inc may change this Privacy Policy from time to
        time. We encourage you to visit this page to stay informed. If the
        changes are material, we may provide you additional notice to your email
        address or through our Services. Your continued use of the Services
        indicates your acceptance of the modified Privacy Policy.
      </p>
      <br />

      <h3>Storage of Information in the United States</h3>
      <p>
        Information we maintain may be stored both within and outside of the
        United States. If you live outside of the United States, you understand
        and agree that we may transfer your information to the United States,
        and that U.S. laws may not afford the same level of protection as those
        in your country.
      </p>
      <br />
      <h3>Contact Us</h3>
      <p>
        If you have questions, comments, or concerns about this Privacy Policy,
        you may contact us at: <strong>admin@fldispatch.us</strong>
      </p>
      
    </div>

  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
h3 {
    margin-bottom: 20px;
}

p {
    margin: 10px 0;
}

ul {
    list-style: none;
}

.wrapper {
    padding: 30px;
}
</style>